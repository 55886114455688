import CourseCard from '@/components/CourseCard/index.vue'
import MyActivityCarousal from '@/components/CourseCarousal/index.vue'
import CircularSubmenu from '@/components/CircularSubmenu/CircularSubmenu.vue'
import Flickity from 'vue-flickity'
import { mapActions, mapGetters } from 'vuex'
// import firebase from 'firebase'

export default {
  name: 'MyCourses',
  components: { CourseCard, Flickity, MyActivityCarousal, CircularSubmenu },
  computed: {
    ...mapGetters({
      getUser: 'getUser',
      getIsAuthenticated: 'getIsAuthenticated',
      getLoginStatus: 'getLoginStatus',
      getSavedContents: 'getSavedContents',
      getUserHistory: 'getUserHistory',
      getUserContents: 'getUserContents'
    })
  },
  mounted () {
    this.GET_SAVED_CONTENTS()
    this.GET_USER_HISTORY()
    this.GET_USER_CONTENTS({
      params: {
        'filter': 'IN_PROGRESS'
      }
    })
  },
  data () {
    return {
      flickityOptions: {
        groupCells: true,
        initialIndex: 0,
        prevNextButtons: true,
        pageDots: false,
        contain: true,
        draggable: false

        // any options from Flickity can be used
      },
      feed1Array: [],
      feed2Array: [],
      feed3Array: [],

      banner_url: '',
      currentActiveIndex: 0,
      courseData: {
        liked: 480,
        content: {
          id: 4,
          rating: 3,
          timeTotal: 120,
          thumbnailUrl: '',
          title: 'Hitchhikers Guide To The Universe',
          description: 'Hitchhikers Guide To The Universe',
          publisher: {
            name: 'Medium'
          },
          category: 'Space Exploration'
        }
      },
      cardColorBlack: {
        'bgColor': 'black',
        'fontColor': 'white',
        'mainColor': 'black',
        'feedColor': 'black'
      },
      cardColorBlackRed: {
        'bgColor': 'black',
        'fontColor': 'white',
        'mainColor': 'red',
        'feedColor': 'black'
      },
      cardColorWhite: {
        'bgColor': 'white',
        'fontColor': 'black',
        'mainColor': 'red',
        'feedColor': 'white'
      },
      bookmarks_feed: {
        feed_heading: 'Bookmarks'
      },
      continueWatching_feed: {
        feed_heading: 'Continue Watching'
      },
      history_feed: {
        feed_heading: 'History'
      }
    }
  },
  methods: {
    ...mapActions({
      GET_SAVED_CONTENTS: 'GET_SAVED_CONTENTS',
      GET_USER_HISTORY: 'GET_USER_HISTORY',
      GET_USER_CONTENTS: 'GET_USER_CONTENTS'
    }),
    showPrevElement () {
      this.$refs.flickity.previous()
    },
    showNextElement () {
      this.$refs.flickity.next()
    }
  }
}
