import $ from 'jquery'

export default {
  name: 'CircularSubmenu',
  props: {
    onArticlePage: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    articlePageMenu () {
      $('.circle--main').css({
        border: 55 + 'px solid black'
      })
      $('.menu__toggle').css({
        'background-color': 'black'
      })
    },
    videoPageMenu () {
      $('.circle--main').css({
        border: 55 + 'px rgb(85, 82, 82);'
      })
      $('.menu__toggle').css({
        'background-color': 'rgb(85, 82, 82);'
      })
    },
    goToHistoryPage () {
      this.$router.push({ path: '/my-courses' })
    },
    doSomething () {
      //
    }
  },
  mounted () {
    this.onArticlePage ? this.articlePageMenu() : this.videoPageMenu()
  }
}
